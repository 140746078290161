<template>
  <div @mouseleave="onMouseLeave">
    <nav>
      <div class="container">
        <ul
          class="flex items-center gap-x-12 overflow-auto snap-x snap-mandatory styled-scrollbars mx-auto w-fit"
        >
          <li
            v-for="link in navigation"
            :key="link.id"
            class="flex-shrink-0 snap-start"
            @mouseenter="setActiveMenu(link)"
            @mouseleave="setActiveMenu(null)"
          >
            <float
              show
              floating-as="template"
              placement="bottom-start"
              :offset="{
                alignmentAxis: (menuItemSpace / 2) * -1,
                crossAxis: 17.5,
                mainAxis: 17.5,
              }"
              flip
              enter="transition duration-150 ease-in"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="transition duration-150 delay-150 ease-out"
              leave-from="opacity-100"
              leave-to="opacity-0"
              tailwindcss-origin-class
            >
              <component
                :is="getComponentType(link)"
                :id="
                  link.url.includes('btn-id-')
                    ? extractButtonId(link.url)
                    : null
                "
                :to="
                  !link.is_title && !link.url.includes('btn-id-')
                    ? localePath(link.url)
                    : null
                "
                class="inline-flex text-xs uppercase"
                :class="[
                  !link.is_title &&
                    'cursor-pointer underline-offset-4 hover:underline',
                  menuOpened &&
                    activeMenu?.id === link.id &&
                    'underline underline-offset-4',
                  Number(activeLink?.id) === link.id
                    ? 'underline underline-offset-4'
                    : '',
                ]"
                :data-e2e-item="!link.is_title ? 'navigation' : null"
              >
                {{ link?.currentTranslation?.title }}
              </component>
              <div
                v-if="link?.children?.length"
                v-show="activeMenu?.id === link.id"
                class="bg-white border border-t-px mt-px border-gray-border py-5.5 pr-7.5 pl-5"
              >
                <template v-for="link3 in link?.children" :key="link3.id">
                  <ul class="space-y-2">
                    <li
                      v-for="link2 in link3.children"
                      :key="link2.id"
                      class="text-xs font-light"
                    >
                      <component
                        :is="getComponentType(link2)"
                        :id="
                          link2.url.includes('btn-id-')
                            ? extractButtonId(link2.url)
                            : null
                        "
                        :to="
                          !link2.is_title && !link2.url.includes('btn-id-')
                            ? localePath(link2.url)
                            : null
                        "
                        class="inline-flex items-center gap-1.5"
                        :class="[
                          !link2.is_title &&
                            'cursor-pointer underline-offset-4 hover:underline',
                          Number(activeLink?.id) === link2.id
                            ? 'font-bold before:mr-1 before:inline-block before:size-2 before:rounded-full before:bg-wood-900'
                            : '',
                        ]"
                        :data-e2e-item="!link2.is_title ? 'navigation' : null"
                      >
                        <img
                          v-if="link2.image || link2.icon"
                          class="h-4 w-4 object-contain"
                          :src="link2.image || link2.icon"
                          :alt="link2?.currentTranslation?.title"
                        />
                        <span v-html="link2?.currentTranslation?.title" />
                      </component>
                    </li>
                  </ul>
                </template>
              </div>
              <div v-else></div>
            </float>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { Float } from "@headlessui-float/vue";
import { NavMenuItem } from "ecom-types";
import { NuxtLink } from "#components";

const { headerMenu } = useGeneralStore();

const localePath = useLocalePathPolyfill();

const activeMenu = ref<TreeItem<NavMenuItem> | null>(null);
let timeout: ReturnType<typeof setTimeout>;

const navigation = computed(() => {
  return toTree(headerMenu);
});

const { findBreadcrumb } = useBreadcrumbs();

const route = useRoute();
const menuItemSpace = 36;

watch(
  () => route.fullPath,
  () => {
    setActiveMenu(null);
  },
  {
    immediate: true,
  },
);

const menuOpened = computed(() => {
  return activeMenu.value && activeMenu.value.children?.length;
});

const activeLink = computed(() => {
  const breadcrumbs = findBreadcrumb(navigation.value, route);
  return breadcrumbs[breadcrumbs.length - 1];
});

function onMouseLeave() {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    setActiveMenu(null);
  }, 350);
}

function setActiveMenu(item: TreeItem<NavMenuItem> | null) {
  clearTimeout(timeout);
  activeMenu.value = item;
}

function getComponentType(link: NavMenuItem) {
  if (link.is_title) {
    return "div";
  } else if (link.url.includes("btn-id-")) {
    return "button";
  } else {
    return NuxtLink;
  }
}
</script>
